
const Footer = () => {


    return (
        <>
        <hr/>
        <div className="mt">
            <p>ColorPicker by <a className="white" href="https://m.cwipedia.in/">CWIPEDIA</a></p>
        </div>
        </>
    )
}
export default Footer;